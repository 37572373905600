<template>
    <div class="workspace__body">
        <h1>Новый пользователь</h1>
        <UserForm :user="{}" :newUser="true" />
    </div>
</template>

<script>
import UserForm from '../../components/UserForm.vue'
export default {
    name: 'UserCreate',
    components: {
        UserForm
    },
}
</script>